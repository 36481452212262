// import * as ActiveStorage from "activestorage";
// import "../utils/direct_uploads.js"

// Materialize.start();
// ActiveStorage.start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

import Rails from "rails-ujs"
Rails.start()

import "../src/phone_formatter"

// CSS
import "../src/css/main.scss"
